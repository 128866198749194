@charset "UTF-8";
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

@font-face {
  font-family: 'futura';
  src: url("../fonts/FuturaNewMedium.eot");
  src: local("☺"), url("../fonts/FuturaNewMedium.woff") format("woff"), url("../fonts/FuturaNewMedium.ttf") format("truetype"), url("../fonts/FuturaNewMedium.svg") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'futura';
  src: url("../fonts/FuturaNewDemi.eot");
  src: local("☺"), url("../fonts/FuturaNewDemi.woff") format("woff"), url("../fonts/FuturaNewDemi.ttf") format("truetype"), url("../fonts/FuturaNewDemi.svg") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'futura';
  src: url("../fonts/FuturaNewBook.eot");
  src: local("☺"), url("../fonts/FuturaNewBook.woff") format("woff"), url("../fonts/FuturaNewBook.ttf") format("truetype"), url("../fonts/FuturaNewBook.svg") format("svg");
  font-weight: 400;
  font-style: normal; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'futura' !important; }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  background: #F5F5F5; }

@media screen and (max-width: 1024px) {
  .ds {
    display: none !important; } }

.background {
  display: block;
  position: absolute;
  z-index: -1;
  left: 50%;
  width: 70vw;
  top: 0;
  margin-left: -35vw;
  transform: translateY(-30%);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .background.isCentered {
    top: 50vh;
    transform: translateY(-50%);
    transition: all 1s ease-in-out; }
    .background.isCentered .background__gradient {
      opacity: 0; }
  .background.isScaled {
    transform: translateY(-50%) scale(1.1);
    opacity: 0; }
  @media screen and (max-width: 1024px) {
    .background {
      transform: translateY(-5%); } }

.background__gradient {
  position: absolute;
  bottom: 0;
  height: 50%;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(245, 245, 245, 0) 0%, whitesmoke 100%);
  opacity: 1;
  transition: all 1s ease-in-out; }

.background__gray {
  display: block;
  width: 100%; }

.contact {
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center; }
  .contact._mail {
    margin-top: 341px; }
    @media screen and (max-width: 1024px) {
      .contact._mail {
        width: 200px;
        margin: 118px auto 0;
        font-size: 20px;
        line-height: 28px; } }
    .contact._mail a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid; }
  .contact._facebook {
    margin-top: 268px;
    margin-bottom: 107px; }
    @media screen and (max-width: 1024px) {
      .contact._facebook {
        margin-top: 100px;
        margin-bottom: 70px; } }
    .contact._facebook .facebook-icon {
      position: relative;
      top: -.1em;
      margin-right: 6px; }
    .contact._facebook a {
      color: inherit;
      text-decoration: none; }

.facebook-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(../images/facebook.svg) center/contain no-repeat; }
  @media screen and (max-width: 1024px) {
    .facebook-icon {
      width: 16px;
      height: 16px; } }

.facebook-link {
  position: absolute;
  right: 62px;
  top: 40px; }
  @media screen and (max-width: 1024px) {
    .facebook-link {
      top: 15px;
      right: 20px; } }

.language-link {
  position: absolute;
  left: 64px;
  top: 40px;
  text-decoration: none;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  line-height: 22px; }
  @media screen and (max-width: 1024px) {
    .language-link {
      top: 15px;
      left: 18px;
      font-size: 12px;
      line-height: 26px; } }

.preview {
  padding-top: 172px;
  text-align: center; }
  @media screen and (max-width: 1024px) {
    .preview {
      padding-top: 80px; } }
  @media screen and (max-width: 414px) {
    .preview {
      text-align: left;
      margin: 0 20px; } }

.preview__faded {
  opacity: 0;
  transform: scale(0.95);
  transition: all .6s ease; }
  .preview__faded.isActive {
    opacity: 1;
    transform: scale(1); }

.preview__title {
  font-size: 45px;
  color: #000000;
  letter-spacing: 0; }
  @media screen and (max-width: 1024px) {
    .preview__title {
      font-size: 33px; } }

.preview__desc {
  margin-top: 96px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  line-height: 22px;
  max-width: 380px; }
  @media screen and (max-width: 1024px) {
    .preview__desc {
      font-size: 18px;
      line-height: 26px;
      margin-top: 51px;
      margin: 51px auto 0;
      max-width: 421px; } }
  @media screen and (max-width: 414px) {
    .preview__desc {
      margin-top: 70px; } }

.preview__menu {
  padding-top: 82px; }
  @media screen and (max-width: 1024px) {
    .preview__menu {
      padding-top: 42px; } }
  @media screen and (max-width: 414px) {
    .preview__menu {
      margin-top: 70px;
      text-align: center; } }

.preview__box-link {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid rgba(151, 151, 151, 0.2);
  width: 192px;
  height: 74px;
  line-height: 74px;
  margin: 30px 32px 0;
  cursor: pointer;
  transition: background .2s ease; }
  @media screen and (max-width: 1024px) {
    .preview__box-link {
      margin: 30px 16px 0; } }
  .preview__box-link:hover {
    background: #fff; }
  .preview__box-link span {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px; }

.project {
  position: relative;
  margin: 60px auto 0;
  height: 48vw;
  max-width: 1440px;
  max-height: 700px;
  min-height: 600px;
  padding: 0 40px;
  overflow: hidden;
  perspective: 800PX; }
  .project .project__inner {
    opacity: 0;
    transform: rotateX(15deg); }
  .project.isActive .project__inner {
    opacity: 1;
    transform: rotateX(0);
    transition: all .6s ease; }
  @media screen and (max-width: 1024px) {
    .project {
      padding: 0 30px;
      margin: 40px auto 0;
      height: auto;
      max-width: none;
      max-height: none;
      min-height: none; } }
  @media screen and (max-width: 414px) {
    .project {
      padding: 0 13px; } }
  .project:first-child {
    margin-top: 0; }

.project__inner {
  position: absolute;
  top: 0;
  left: 40px;
  right: 40px;
  bottom: 0;
  transform-style: preserve-3d;
  transform-origin: 50% 150%;
  backface-visibility: hidden; }
  @media screen and (max-width: 1024px) {
    .project__inner {
      position: static;
      left: 30px;
      right: 30px; } }
  @media screen and (max-width: 414px) {
    .project__inner {
      left: 13px;
      right: 13px; } }

.project__info {
  position: relative;
  float: left;
  height: 100%;
  width: 50%;
  background: #fff;
  padding: 43px 64px 0; }
  .project__info:before {
    content: attr(data-count);
    position: absolute;
    top: 0;
    top: -65px;
    right: -70px;
    font-weight: 500;
    font-size: 468px;
    color: #F5F5F5;
    letter-spacing: 0;
    pointer-events: none; }
    @media screen and (max-width: 414px) {
      .project__info:before {
        right: -95px;
        top: -75px; } }
  @media screen and (max-width: 414px) {
    .project__info._n1:before {
      right: -60px; } }
  @media screen and (max-width: 1024px) {
    .project__info {
      float: none;
      height: 450px;
      width: auto;
      padding: 40px 32px 0; } }
  @media screen and (max-width: 414px) {
    .project__info {
      padding: 40px 17px 0;
      height: auto; } }

.project__title {
  position: relative;
  z-index: 1;
  font-size: 44px;
  color: #000000;
  letter-spacing: 0; }

.project__desc {
  position: relative;
  z-index: 1;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 74px;
  padding-left: 64px; }
  @media screen and (min-width: 1025px) {
    .project__desc {
      max-width: 320px; } }
  @media screen and (max-width: 1024px) {
    .project__desc {
      margin-top: 30px;
      padding-left: 0; } }

.project__blocks {
  display: flex;
  flex-wrap: wrap;
  padding-left: 64px;
  position: absolute;
  z-index: 1;
  bottom: 80px;
  width: 380px;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .project__blocks {
      width: 80%;
      bottom: 59px;
      padding-left: 0; } }
  @media screen and (max-width: 414px) {
    .project__blocks {
      width: 100%;
      position: relative;
      bottom: 0;
      margin-top: 30px;
      padding-bottom: 60px; } }

.project__block {
  width: 50%;
  margin-top: 44px; }
  @media screen and (max-width: 1024px) {
    .project__block {
      margin-top: 30px; } }

.project__block__title {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px; }

.project__block__desc {
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 4px; }
  .project__block__desc a {
    font-size: 14px;
    color: #24509A;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none; }

.project__slider {
  position: relative;
  float: left;
  height: 100%;
  width: 50%; }
  @media screen and (max-width: 1024px) {
    .project__slider {
      height: 90vh;
      width: auto;
      float: none; } }
  @media screen and (max-width: 414px) {
    .project__slider {
      height: 80vh; } }

.project__images {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }
  .project__images .swiper-button {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 50%;
    opacity: 0; }
    .project__images .swiper-button.-prev {
      left: 0;
      cursor: url(../images/cursor-left.png), auto; }
    .project__images .swiper-button.-next {
      right: 0;
      cursor: url(../images/cursor-right.png), auto; }
    @media screen and (max-width: 1024px) {
      .project__images .swiper-button {
        display: none !important; } }
  .project__images .swiper-pagination {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 2;
    bottom: 70px;
    left: 0;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0; }
    @media screen and (max-width: 1024px) {
      .project__images .swiper-pagination {
        bottom: 32px; } }
    .project__images .swiper-pagination .swiper-pagination-bullet {
      border-radius: 0;
      font-size: 0;
      width: 90px;
      height: 3px;
      background: rgba(255, 255, 255, 0.4);
      margin: 0 20px;
      transition: background-color .2s ease; }
      @media screen and (max-width: 1024px) {
        .project__images .swiper-pagination .swiper-pagination-bullet {
          margin: 0 15px;
          width: 40px; } }
      .project__images .swiper-pagination .swiper-pagination-bullet button {
        display: none; }
      .project__images .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: white; }

.project__images__item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center; }

.projects {
  margin-top: 140px; }
  @media screen and (max-width: 1024px) {
    .projects {
      margin-top: 70px; } }
